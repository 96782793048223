<template>
	<div>
		<div class="page-title">
			<span style="font-size: 20px;">文章列表</span>
		</div>
		<div class="bg-white p-2 m-2">
			<div class="d-flex align-items-center">
				<div class="d-flex align-items-center m-1">
					<el-button type="primary" @click="openDialog()" size="mini">添加文章</el-button>
				</div>
			</div>
			<el-table border class="mt-3" :data="lists.data" style="width: 100%">
				<el-table-column align="center" width="120" prop="article_id" label="文章ID"></el-table-column>
				<el-table-column align="center"  label="文章标题">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.article_title}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="封面" width="90">
					<template slot-scope="scope">
						<viewer>
							<img style="width: 45px;height: 45px;" :src="scope.row.image">
						</viewer>
					</template>
				</el-table-column>
				<el-table-column align="center"  label="文章分类">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.category.name}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center"  label="文章排序">
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.article_sort}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" prop="article_status" label="是否显示">
					<template slot-scope="scope">
						<el-switch v-model="scope.row.article_status" :active-value="10" @change="handleStatus(scope.row)"
							:inactive-value="20"></el-switch>
					</template>
				</el-table-column>
				<el-table-column align="center" label="添加时间" >
					<template slot-scope="scope">
						<span style="font-weight: 800;">{{scope.row.create_time}}</span>
					</template>
				</el-table-column>
				<el-table-column align="center" label="操作" >
					<template slot-scope="scope">
						<el-button type="text" size="mini" @click="openDialog(scope.row)" class="table-btn">修改</el-button>
						<el-button type="text" size="mini" @click="deleteItem(scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div style="height: 30px;" class="mt-2 px-2">
				<el-pagination :page-size="lists.per_page" :current-page.sync="search.page"
					style="display: inline-block;float: right;" background @current-change="pageChange"
					layout="prev, pager, next" :total="lists.total">
				</el-pagination>
			</div>
			<el-dialog :title="dialogTitle" :visible.sync="dialogVisible" top="3vh" width="650px" height="60%">
				<!-- 添加/修改规则 -->
				<el-form :rules="rules" ref="form" :model="form" label-width="100px">
					<el-tabs v-model="activeName">
						<el-tab-pane label="基础设置" name="basic">
							<el-form-item label="分类" prop="category_id">
								<el-select v-model="form.category_id" placeholder="请选择">
									<el-option v-for="item in category" :key="item.category_id" :label="item.name"
										:value="item.category_id"></el-option>
								</el-select>
							</el-form-item>
							<el-form-item label="文章标题" prop="article_title">
								<el-input v-model="form.article_title" placeholder="名称"></el-input>
							</el-form-item>
							<el-form-item label="封面图" prop="image">
								<app-attachment :multiple="false" :max="1" v-model="form.image">
									<el-button style="margin-bottom: 10px;" size="mini">选择文件</el-button>
								</app-attachment>
								<app-gallery :url="form.image" :show-delete="true" @deleted="form.image = ''"
									width="80px" height="80px"></app-gallery>
								<div class="pic-url-remark">封面图片尺寸推荐750*350</div>
							</el-form-item>
							<el-form-item label="是否显示" prop="article_status">
								<el-radio v-model="form.article_status" :value="10" :label="10">
									显示
								</el-radio>
								<el-radio v-model="form.article_status" :value="20" :label="20">
									隐藏
								</el-radio>
							</el-form-item>
							<el-form-item label="排序" prop="article_sort">
								<el-input-number :min="0" v-model="form.article_sort" placeholder="排序"></el-input-number>
							</el-form-item>
							<el-form-item label="链接">
								<el-input v-model="form.link_url" placeholder="链接"></el-input>
							</el-form-item>
						</el-tab-pane>
						<el-tab-pane label="文章内容" name="article_content">
							<el-form-item label="文章内容">
								<app-rich-text :key="timer" v-model="form.article_content"></app-rich-text>
							</el-form-item>
						</el-tab-pane>
					</el-tabs>
				</el-form>
				<span slot="footer" class="dialog-footer">
					<el-button type="primary" @click="submit('form')">确 定</el-button>
					<el-button @click="dialogVisible = false">取 消</el-button>
				</span>
			</el-dialog>
		</div>
	</div>
</template>

<script>
	import appRichText from '@/components/app-rich-text.vue';
	export default {
		inject: ['layout'],
		components: {
			appRichText
		},
		data() {
			return {
				timer: new Date().getTime(),
				lists: [],
				category:[],
				activeName: 'basic',
				dialogVisible: false,
				dialogTitle: '添加文章',
				form: {
					article_title: '',
					category_id: '',
					image: '',
					article_content: '',
					link_url: '',
					article_status: 10,
					article_sort: 50,
				},
				rules: {
					category_id: [{
						required: true,
						message: '请输选择分类',
						trigger: 'change'
					}],
					article_title: [{
						required: true,
						message: '请输入名称',
						trigger: 'change'
					}],
					article_sort: [{
						required: true,
						message: '请输入排序',
						trigger: 'change'
					}],
					image: [{
						required: true,
						message: '请选择图片',
						trigger: 'change'
					}, ],
				},
				search: {
					s: 'store/content.article/index',
					page: 1,
				},
			}
		},
		created() {
			this.getList();
			this.getCateList();
		},
		methods: {
			getCateList(){
				this.axios({
					token: true,
					params: {
						s:'store/content.article/category'
					},
					method: 'get',
				}).then(res => {
					this.category = res.data.data
				}).catch(err => {
				})
			},
			// 打开弹出层
			openDialog(item = false) {
				this.timer = new Date().getTime()
				// 初始化表单
				if (!item || typeof item === 'number') {
					this.form = {
						article_title: '',
						category_id: '',
						image: '',
						article_content: '',
						link_url: '',
						article_status: 10,
						article_sort: 50,
					}
					this.article_id = 0
				} else {
					this.axios({
						token: true,
						params: {
							article_id: item.article_id,
							s: 'store/content.article/edit'
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.dialogTitle = '编辑文章'
							this.form = {
								article_title: item.article_title,
								category_id: item.category_id,
								image: item.image,
								article_content: item.article_content,
								link_url: item.link_url,
								article_status: item.article_status,
								article_sort: item.article_sort,
							}
							this.article_id = item.article_id
							
						} else {
							return false;
						}
					}).catch(err => {
					})
				}
				// 显示弹出层
				this.dialogVisible = true
			},
			// 提交
			submit(formName) {
				this.$refs[formName].validate((valid) => {
					if (valid) {
						this.layout.showLoading()
						let params = {
							s: 'store/content.article/add'
						}
						if (this.article_id > 0) {
							params.s = 'store/content.article/edit';
							params.article_id = this.article_id;
						}
						this.axios({
							token: true,
							params: params,
							data: {
								article: this.form
							},
							method: 'post',
						}).then(res => {
							if (res.data.code == 1) {
								this.$message({
									message: res.data.msg,
									type: 'success'
								});
								this.dialogVisible = false;
								this.getList()
							} else {
								this.$message({
									message: res.data.msg,
									type: 'error'
								});
							}
							this.layout.hideLoading()
						}).catch(err => {
							this.layout.hideLoading()
						})
					}
				})
			},
			handleStatus(column) {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: {
						s: 'store/content.article/state',
						article_id: column.article_id,
					},
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					if (res.data.code === 1) {
						this.$message({
							message: res.data.msg,
							type: 'success'
						});
					} else {
						this.$alert(res.data.msg, '提示', {
							confirmButtonText: '确定'
						})
					}
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			toSearch() {
				this.search.page = 1;
				this.getList()
			},
			// 分页
			pageChange(page) {
				this.search.page = page;
				this.getList();
			},
			getList() {
				this.layout.showLoading()
				this.axios({
					token: true,
					params: this.search,
					method: 'get',
				}).then(res => {
					this.layout.hideLoading()
					this.lists = res.data.data
				}).catch(err => {
					this.layout.hideLoading()
				})
			},
			// 删除单个
			deleteItem(data) {
				this.$confirm('是否要删除该文章吗?', '提示', {
					confirmButtonText: '删除',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.layout.showLoading()
					this.axios({
						token: true,
						params: {
							s: 'store/content.article/delete',
							article_id: data.article_id
						},
						method: 'get',
					}).then(res => {
						if (res.data.code == 1) {
							this.$message({
								message: res.data.msg,
								type: 'success'
							});
							this.getList()
						}
						this.layout.hideLoading()
					}).catch(err => {
						this.layout.hideLoading()
					})
				})
			},
		},
	}
</script>

<style>

</style>
